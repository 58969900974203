<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
            allowClear
            v-model="searchParams.productName"
            size='small'
            style="width:150px;margin:5px;"
            placeholder='标题or商品Id'
        ></a-input>
        <a-input
            allowClear
            v-model="searchParams.userName"
            size='small'
            style="width:150px;margin:5px;"
            placeholder='发布者OrUserId'
        ></a-input>

        <a-select
            allowClear
            autocomplete="off"
            v-model="searchParams.onShelf"
            size='small'
            style="width:100px;margin:5px;"
            placeholder='上下架'
        >
          <a-select-option :value='1'>上架</a-select-option>
          <a-select-option :value='0'>下架</a-select-option>
        </a-select>

        <a-select
            allowClear
            v-model="searchParams.reviewStatus"
            size='small'
            style="width:100px;margin:5px;"
            placeholder='审核'
        >
          <a-select-option :value='0'>待审核</a-select-option>
          <a-select-option :value='1'>推荐</a-select-option>
          <!-- <a-select-option :value='-1'>异常</a-select-option> -->
          <a-select-option :value='3'>处理</a-select-option>
          <a-select-option :value='2'>通过</a-select-option>

        </a-select>
        <a-range-picker

            :placeholder="['开始日期','结束日期']"
            size='small'
            style="width:200px;margin:5px;"
            @change="searchPickerChange"
        />
        <a-input
            v-model="searchParams.coinKindName"
            size='small'
            style="width:150px;margin:5px;"
            placeholder='大币种'
        ></a-input>

        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="width: 150px; margin: 5px"
            v-model="params.appreciationType"
            placeholder="赏析"
        >
          <a-select-option :value="1">有</a-select-option>
          <a-select-option :value="-1">无</a-select-option>
        </a-select>
        <a-button
            @click="search"
            size='small'
            type="primary"
            style="margin-left:10px;"
        >搜索</a-button>
      </div>

      <a-button
          v-if="false"
          size='small'
          style="margin:5px;"
          @click="openModal({})"
      >录入商品</a-button>
    </div>
    <div class="table-content">
      <a-table
          @change="changePage"
          :pagination='pagination'
          :loading='loading'
          :columns="columns"
          :data-source="data"
          :rowKey="(record,n)=>n"
      >
        <div
            slot="idSlot"
            slot-scope="row"
        >
          <div style="max-width:150px;">
            <a-popover
                title="商品编号"
                trigger="hover"
            >
              <template slot="content">
                <p>{{row.productNo}}</p>
              </template>

              <a
                  style='color:blue'
                  @click="copy(row.productNo)"
                  type="link"
              >
                {{row.id}}
              </a>

            </a-popover>

          </div>
        </div>


        <div
            slot="images"
            slot-scope="row"
        >
          <div style='max-width:100px;max-height:100px;display:flex;justify-content:center;align-items:center;'>
            <img
                @click="previewImg(row.coverUrls)"
                style='max-width:100px;max-height:100px;'
                :src='row.coverUrl'
            />
          </div>
        </div>
        <!-- 裁剪图片 -->
        <div
            slot="cutImages"
            slot-scope="row"
        >
          <div
              v-if="row.cutCoverUrl"
              style='max-width:100px;max-height:100px;display:flex;flex-direction: column;justify-content:center;align-items:center;'
          >
            <img
                @click="previewImg( [row.cutCoverUrl])"
                style='max-width:100px;max-height:100px;margin-bottom: 6px'
                :src='row.cutCoverUrl || ""'
            />
            <a-popconfirm
                title="取消裁剪封面,用第一张图作为封面"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteImage(row)"
            >
              <a-button
                  size="small"
                  style='margin-left:5px'
              >删除</a-button>
            </a-popconfirm>
          </div>
        </div>
        <div
            slot="description"
            slot-scope="row"
        >
          <div style="max-width:150px;">
            <a-popover
                title="商品完整标题及补充信息"
                trigger="hover"
            >
              <template slot="content">
                <p>{{row.productName}}</p>
                <div></div>
                <p>补充信息:</p>
                <p>用户自定义瑕疵：{{computedProductRemark(row.productRemark, 'customRemark')}}</p>
                <p>选择瑕疵：{{computedProductRemark(row.productRemark, 'coinStateRemark')}}</p>
              </template>

              <a
                  v-if="row.isGrayUser == 1"
                  style='color:gray'
                  @click="copy(row.productRemark)"
                  type="link"
              >
                {{row.productName}}
              </a>
              <a
                  v-if="row.isGrayUser == 0"
                  @click="copy(row.productRemark)"
                  type="link"
              >
                {{row.productName.substring(0,14)}}
                <!-- {{row.productName}} -->
              </a>

            </a-popover>

            <p style="color:rgb(217,13,38);font-weight:bold;">￥{{row.finalPrice}}</p>
            <!-- <p
              v-if="row.reviewStatus===-1"
              style="color: red"
            >异常</p> -->
            <p v-if="row.reviewStatus===0" style="color: #1890ff" >待审核</p>
            <p v-if="row.reviewStatus===1" style="color: green">推荐</p>
            <p v-if="row.reviewStatus===2">一般</p>
            <p v-if="row.reviewStatus===3" style="color: red">
              处理
              </br>
              {{row.reviewDetail.reviewReason}}
            </p>

          </div>
        </div>

        <div
            slot="tags"
            slot-scope="row"
        >
          <a-tag
              v-for="(tag,key) in row"
              :key="key"
              color="blue"
          >{{ tag }}</a-tag>
        </div>

        <div
            slot="userName"
            slot-scope="row"
        >
          <span
              v-if="row.isGrayUser == 1"
              style='color: #1890ff'
              @click="userIdSearch(row.userId)"
              type="link"
          >
            {{ row.userName }}
          </span>
          <span
              v-if="row.isGrayUser == 0"
              style='color: #1890ff'
              @click="userIdSearch(row.userId)"
              type="link"
          >
            {{ row.userName }}
          </span>
        </div>

        <div
            slot="setup"
            slot-scope="row"
        >
          <template>
            <!-- <div style="max-width:250px;"> -->
            <a-popover v-model:visible="row.id == reviewProductId" title="审核" trigger="click" @visibleChange="closeReviewModel">
              <template  slot="content">
                <div>
                  <a-button type="primary" style="margin-left:20px;" @click="reviewHandle(row.id,1)">精品</a-button>
                  <a-button type="primary" style="margin-left:120px;"  @click="reviewHandle(row.id,2)">普品</a-button>
                  <a-button type="primary" style="margin-left:120px;"  @click="reviewHandle(row.id,3)">差品</a-button>
                </div>

                <div>
                  <a-select
                      allowClear
                      v-model="reviewHandleStatus"
                      size='small'
                      style="width:350px;margin:20px;"
                      placeholder='处理'
                  >
                    <a-select-option
                        v-for="item of reviewHandleStatusList"
                        :key="item.value"
                        :value="item.value"
                    >{{ item.name }}</a-select-option>

                  </a-select>
                </div>
                <a-button type="danger" style="margin-left:20px;" @click="reviewHandle(row.id,-1)">拒绝（驳回）</a-button>

                <a style="margin-left:80px;"  @click="hideReviewModal">关闭</a>
              </template>
            </a-popover>
            <a-button type="primary" size="small" class="ml-10 mb-10" @click="clickReview(row.id)">审核</a-button>
            <!-- </div> -->
          </template>

          <a-button
              size="small"
              @click="openModal(row,1)"
              style='margin-right:5px'
          >编辑</a-button>
          <a-button
              size="small"
              @click="appreciate(row)"
              style="margin-right: 5px"
          >鉴赏</a-button>

          <a-popconfirm
              v-if="row.onShelf === 0"
              title="确认上架吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="racking(row,1)"
          >
            <a-button
                v-if="false"
                size="small"
                style='margin-left:5px'
            >上架</a-button>
          </a-popconfirm>

          <a-popconfirm
              v-if="row.onShelf === 1"
              title="确认下架吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="racking(row,0)"
          >
            <a-button
                v-if="false"
                size="small"
                style='margin-left:5px'
            >下架</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="确认删除么?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove(row.productNo)"
          >
            <a-button
                size="small"
                type="danger"
                style='margin-left:5px'
            >删除</a-button>
          </a-popconfirm>

          <a-button
              size="small"
              style='margin-left:5px'
              @click="downloadCode(row)"
          >下载海报</a-button>

        </div>

      </a-table>
    </div>

    <a-modal
        :width='800'
        v-model="visible"
        title="录入商品"
        ok-text="确认"
        cancel-text="取消"
        @cancel='resetForms'
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="forms"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item
            label="用户"
            prop="userId"
        >
          <a-select
              size='small'
              v-model="forms.userId"
              :filter-option="untils.filterOption"
              showSearch
              placeholder='请选择'
          >
            <a-select-option
                v-for="item of users"
                :key="item.id"
                :value='item.id'
            >
              <div style="display:flex;align-items:center;">
                <img
                    style="max-width:20px;max-height:20px;border-radius:50%;margin-right:10px;"
                    :src="item.userAvatar"
                    alt=""
                >
                <span :text='item.userName'>{{item.userName}}</span>
              </div>
              <!-- {{item.userName}} -->
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="图片">
          <a-upload
              :key="imgKey"
              :action="ip+'/file/upload'"
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item
            label="价格"
            prop="finalPrice"
        >
          <a-input
              size='small'
              placeholder='请输入价格'
              v-model="forms.finalPrice"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
            label="运费"
            prop="logisticsFee"
        >
          <a-input
              size='small'
              placeholder='请输入运费0元即为包邮'
              v-model="forms.logisticsFee"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item label="标签">
          <a-tag
              closable
              @close="forms.tags.splice(key,1)"
              v-for="(item,key) of forms.tags"
              :key="key"
          >{{item}}</a-tag>
          <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
          />
          <a-tag
              v-if="!inputVisible"
              style="background: #fff; borderStyle: dashed;"
              @click="showInput"
          >
            <a-icon type="plus" /> New Tag
          </a-tag>
        </a-form-model-item>

        <a-form-model-item
            label="大币种板块"
            prop="coinSid"
        >
          <a-select
              size='small'
              @search='searchData'
              v-model="forms.coinSid"
              :filter-option="untils.filterOption"
              showSearch
              placeholder='请选择'
          >
            <a-select-option
                v-for="item of currency_big_list"
                :key="item.id"
                :value='item.sid'
            >{{item.coinKindName}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="文字内容"
            prop="productName"
        >
          <a-textarea
              auto-size
              size='small'
              placeholder='请输入'
              v-model="forms.productName"
          ></a-textarea>
        </a-form-model-item>

        <a-form-model-item
            label="发布时间"
            prop="start_time"
        >
          <a-date-picker
              show-time
              size='small'
              :defaultValue="moment(new Date, 'YYYY-MM-DD')"
              placeholder='选择日期'
              @change="changeTime"
          />
        </a-form-model-item>

        <a-form-model-item
            v-if="false"
            label="商品备注"
            prop="productRemark"
        >
          <a-input
              v-model="forms.productRemark"
              type="textarea"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
        :visible="previewVisible"
        :footer="null"
        @cancel="handleCancel"
    >
      <img
          alt="example"
          style="width: 100%"
          :src="previewImage"
      />
    </a-modal>

    <a-modal
        centered
        :width='800'
        v-model="visiblePrice"
        title="出价记录"
        @cancel='cancelModal'
        :footer="null"
    >
      <div class="take-box">
        <a-table
            @change="changePageModal"
            :pagination='paginationModal'
            :loading='loadingModal'
            :columns='takeColumns'
            :data-source='messages'
            :rowKey="(record,n)=>n"
        >
        </a-table>
      </div>
    </a-modal>

    <!-- 鉴赏 -->
    <AddGoodsAppreciate
        ref="add-appreciate"
        :detail="detail"
        @success="getData"
    ></AddGoodsAppreciate>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import AddGoodsAppreciate from "@/views/cmsPage/productManage/fixedPriceProductList/_components/addGoodsAppreciate.vue";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  data() {
    return {
      detail: "",
      columns: [
        { title: "ID", scopedSlots: { customRender: "idSlot" } },
        { title: "图片", scopedSlots: { customRender: "images" } },
        // { title: "裁剪封面", scopedSlots: { customRender: "cutImages" } },
        { title: "标题", scopedSlots: { customRender: "description" } },
        { title: "发布者", scopedSlots: { customRender: "userName" } },
        { title: "币种", dataIndex: "coinKindName" },
        {
          title: "标签",
          dataIndex: "tags",
          scopedSlots: { customRender: "tags" },
        },

        { title: "发布时间", dataIndex: "createTime" },
        // { title: "修改时间", dataIndex: "updateTime" },
        {
          title: "状态",
          customRender: (text, row, index) => {
            const { statusName, onShelf, reviewStatus, reviewDetail } = row;
            return onShelf === 1 ? (
                <span style="color:green">{statusName}</span>
            ) : (
                <span style="color:red">{ reviewStatus === -1 ? `已驳回（${reviewDetail ? reviewDetail.reviewReason : ''}）` : statusName}</span>
            );
          },
          width: 200
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      times: [],
      messages: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      forms: {},
      visiblePrice: false,
      users: [],
      fileList: [],
      rules: {
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        finalPrice: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ],
        coinSid: [
          { required: true, message: "请选择大币种版别", trigger: "change" },
        ],
        productName: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        logisticsFee: [
          { required: true, message: "请输入运费0元即为包邮", trigger: "blur" },
        ],
      },
      visible: false,
      params: { pageNum: 1, pageSize: 10 },
      IO: 0,
      tagCurrent: "",
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      checkAll: false,
      indeterminate: false,
      IO: 0,
      searchTime: [],
      currency_big_list: [],
      searchParams: {
        userName: "",
        content: "",
        startTime: "",
        endTime: "",
        coinKindName: "",
        productKind: "",
        productName: "",
        // onShelf: "",
      },
      previewVisible: false,
      previewImage: "",
      inputValue: "",
      inputVisible: false,
      imgKey: "",
      kindList: [],
      paginationModal: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loadingModal: false,
      takeColumns: [
        { title: "昵称", dataIndex: "userName" },
        { title: "出价", width: "200", dataIndex: "price" },
        {
          title: "时间",
          width: "200",
          align: "center",
          dataIndex: "auctionTime",
        },
      ],
      modalParams: {},
      reviewHandleStatus: undefined,
      reviewProductId: "",
      reviewHandleStatusList:[
        { name: "法律法规禁止", value: 0, },
        { name: "图片原因(不清晰/展示不完整/遮挡/带其他平台水印等)", value: 1, },
        { name: "评级信息无法查询", value: 2, },
        { name: "图文及描述明显冲突或错误,或明显误导容易引起歧义", value: 3, },
        { name: "平台暂不支持", value: 4, },
        { name: "明显存疑或赝品", value: 5, }
      ],

    };
  },
  components: {
    AddGoodsAppreciate,
  },
  created() {
    const { userName,productName } = this.$route.query;
    if (userName) {
      this.searchParams.userName = userName;
    }
    if (productName) {
      this.searchParams.productName = productName;
    }
    this.getData();
    this.getUsers();
    this.getKinds();
  },
  mounted() {
    addEventListener("paste", (e) => {
      if (this.visible) {
        this.untils.pasteImg(e, 1, this.paseImgs);
      }
    });
  },
  computed: {
    ...mapState(["ip"]),
    computedProductRemark() {
      return (remark, type) => {
        if (remark) {
          if (remark.includes(type)) {
            return JSON.parse(remark)[type]
          } else {
            return  ''
          }
        } else {
          return ''
        }
      }
    }
  },
  methods: {
    moment,
    // 删除裁剪封面图
    async deleteImage(row) {
      const res = await this.axios("/dq_admin/product/cancelCutCover", {
        params: {
          productId: row.id
        }
      });
      if (res.status != 200) return
      this.$message.success(res.message);
      this.getData()
    },
    appreciate(row) {
      this.detail = JSON.parse(JSON.stringify(row));
      this.$refs["add-appreciate"].show = true;
    },

    openModal(row, type) {
      this.visible = true;
      this.imgKey = Math.random();
      this.fileList = [];
      if (type) {
        this.IO = 1;
        this.forms = JSON.parse(JSON.stringify(row));
        const {coinSid,coinKindName} = this.forms
        if(coinSid){
          this.currency_big_list = [{id:1,sid:coinSid,coinKindName}]
        }
        const { coverUrls } = row || { coverUrls: [] };
        if (coverUrls && coverUrls.length > 0) {
          this.fileList = coverUrls.map((items, i) => {
            const obj = {
              uid: i,
              name: "image",
              status: "done",
              url: items,
            };
            return obj;
          });
        }
      } else {
        this.IO = 0;
        this.forms = {
          tags: [],
          coverUrls: [],
        };
      }
    },
    copy_text(text) {
      let oInput = document.createElement("input");
      oInput.value = text;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message.success("已复制进剪切板");
    },
    copy(text) {
      this.copy_text(text);
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    resetForms() {
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },
    async getKinds() {
      const res = await this.axios("/dq_admin/kind/list");
    },
    submit(forms) {
      const postData = JSON.parse(JSON.stringify(forms));
      const images = this.fileList.map((row) => row.url);
      postData.coverUrls = images;
      const post_url = this.IO
          ? "/dq_admin/product/edit"
          : "/dq_admin/product/addProduct";
      this.axios.post(post_url, { ...postData }).then((res) => {
        if (res.status == "200") {
          this.$message.success(res.message);
          this.visible = false;
          this.getData();
        }
      });
    },
    async getData() {
      this.loading = true;
      const res = await this.axios("/dq_admin/product/getOnePriceList", {
        params: {
          ...this.params,
          ...this.searchParams,
        },
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    async getUsers() {
      const res = await this.axios("/dq_admin/user/list");
      const { data } = res;
      this.users = data;
    },
    search() {
      const result = this.times.map((row) => {
        const time = new Date(row._d).toLocaleDateString().replace(/\//g, "-");
        return time;
      });
      if (result && result.length > 0) {
        [this.searchParams.startTime, this.searchParams.endTime] = [...result];
      }
      this.params.pageNum = 1;
      this.getData();
    },
    userIdSearch(id) {
      this.params.pageNum = 1;
      this.searchParams.userName = id;
      this.getData();
    },
    async remove(n) {
      const res = await this.axios.post("/dq_admin/product/delProduct", {
        productNo: n,
      });
      if (res.status == "200") {
        this.$message.success(res.message);
        this.getData();
      }
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    searchData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.currency_big_list = records;
        }
      });
    },
    changeTime(time) {
      const { _d } = time;
      // this.forms.createTime = new Date(_d)
      //   .toLocaleTimeString()
      //   .replace(/\//g, "-");

      var dayStr = new Date(_d).toLocaleDateString().replace(/\//g, "-");

      var hourMinStr = new Date(_d).toLocaleTimeString().replace(/\//g, "-");

      this.forms.createTime = dayStr + " " + hourMinStr;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      const tags = this.forms.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags.push(inputValue);
      }
      Object.assign(this, {
        inputVisible: false,
        inputValue: "",
      });
    },
    async setProduct(row, type) {
      const res = await this.axios.post("/dq_admin/ali/editWeight", {
        productNo: row.productNo,
        type: type,
      });
      if (res.status == "200") {
        this.$message.success(res.message);
        this.getData();

        this.searchParams.productName.value = "";
        this.select.value = "";
        // this.searchParams.userId = "";
        this.searchParams.userName = "";
        this.searchParams.productName = "";
        this.searchParams.content = "";
        this.searchParams.startTime = "";
        this.searchParams.endTime = "";
        this.searchParams.coinKindName = "";
        this.searchParams.productKind = "";
        this.searchParams.onShelf = "";
      }
    },
    previewImg(urls) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    paseImgs(type, data, row) {
      const len = this.fileList.length + 1;
      const obj = { uid: len, name: "image", status: "done", url: data };
      this.fileList.push(obj);
    },
    async racking(row, type) {
      const res = await this.axios.post("/dq_admin/product/racking", {
        productNo: row.productNo,
        type,
      });
      if (res.status == 200) {
        this.$message.success(res.message);
        this.getData();
      }
    },
    downloadCode(row) {
      const { productNo } = row;
      this.axios("/dq_admin/product/syntheticImg", {
        params: { productNo },
      }).then((res) => {
        const { status, data, message } = res;
        if (status == "200") {
          const alink = document.createElement("a");
          alink.href = data + "?response-content-type=application/octet-stream";
          alink.download = `${productNo}海报`;
          alink.click();
          alink.remove();
          this.$message.success(message);
        }
      });
    },

    // 竞拍记录
    changePageModal(page) {
      const { current } = page;
      this.paginationModal.current = current;
      this.modalParams.pageNum = current;
      this.getTakeMessages(this.modalParams);
    },
    openPriceModal(productNo) {
      this.visiblePrice = true;
      Object.assign(this.modalParams, {
        pageNum: 1,
        pageSize: 10,
        productNo: productNo,
      });
      this.getTakeMessages(this.modalParams);
    },
    cancelModal() {
      // this.modalParams = {}
      this.messages = [];
    },
    async getTakeMessages(params) {
      this.loadingModal = true;
      const result = await this.axios(
          "/dq_admin/productUserBid/getAuctionLog",
          { params: params }
      );
      this.loadingModal = false;
      if (result.status == 200) {
        const { records = [], total } = result.data.page;
        this.messages = records;

        this.paginationModal.total = total;
      }
    },
    // 搜索日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.searchParams.startTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.searchParams.endTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.searchParams.startTime = "";
        this.searchParams.endTime = "";
      }
    },
    hideReviewModal() {
      this.reviewProductId = "";
      this.reviewHandleStatus = undefined;
    },

    clickReview(id){
      this.reviewProductId = id;
      this.reviewHandleStatus = undefined;
    },

    async reviewHandle(id, reviewStatus){
      let reviewReasion = "";
      if(reviewStatus === -1){
        if(this.reviewHandleStatus.length === 0){
          return this.$message.warning("选择处理明细");
        }
        const result = this.reviewHandleStatusList.find(el=>{
          return this.reviewHandleStatus === el.value
        })
        reviewReasion = result.name;
      }

      const res = await this.axios.post("/dq_admin/product/review", {
        productId: id,
        reviewStatus: reviewStatus,
        reviewReason: reviewReasion,
      });
      if (res.status == 200) {
        this.$message.success(res.message);
        this.hideReviewModal();
        this.getData();
      }else{
        this.$message.warning(res.message);
      }
    },
    closeReviewModel(){
    }
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>