var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "search-attr" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "标题or商品Id"
                },
                model: {
                  value: _vm.searchParams.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "productName", $$v)
                  },
                  expression: "searchParams.productName"
                }
              }),
              _c("a-input", {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "发布者OrUserId"
                },
                model: {
                  value: _vm.searchParams.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "userName", $$v)
                  },
                  expression: "searchParams.userName"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    autocomplete: "off",
                    size: "small",
                    placeholder: "上下架"
                  },
                  model: {
                    value: _vm.searchParams.onShelf,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "onShelf", $$v)
                    },
                    expression: "searchParams.onShelf"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("上架")
                  ]),
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("下架")
                  ])
                ],
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100px", margin: "5px" },
                  attrs: { allowClear: "", size: "small", placeholder: "审核" },
                  model: {
                    value: _vm.searchParams.reviewStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "reviewStatus", $$v)
                    },
                    expression: "searchParams.reviewStatus"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("待审核")
                  ]),
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("推荐")
                  ]),
                  _c("a-select-option", { attrs: { value: 3 } }, [
                    _vm._v("处理")
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("通过")
                  ])
                ],
                1
              ),
              _c("a-range-picker", {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: { placeholder: ["开始日期", "结束日期"], size: "small" },
                on: { change: _vm.searchPickerChange }
              }),
              _c("a-input", {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: { size: "small", placeholder: "大币种" },
                model: {
                  value: _vm.searchParams.coinKindName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "coinKindName", $$v)
                  },
                  expression: "searchParams.coinKindName"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "150px", margin: "5px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    size: "small",
                    placeholder: "赏析"
                  },
                  model: {
                    value: _vm.params.appreciationType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "appreciationType", $$v)
                    },
                    expression: "params.appreciationType"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("有")
                  ]),
                  _c("a-select-option", { attrs: { value: -1 } }, [
                    _vm._v("无")
                  ])
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          false
            ? _c(
                "a-button",
                {
                  staticStyle: { margin: "5px" },
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.openModal({})
                    }
                  }
                },
                [_vm._v("录入商品")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "idSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticStyle: { "max-width": "150px" } },
                      [
                        _c(
                          "a-popover",
                          { attrs: { title: "商品编号", trigger: "hover" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c("p", [_vm._v(_vm._s(row.productNo))])
                            ]),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "blue" },
                                attrs: { type: "link" },
                                on: {
                                  click: function($event) {
                                    return _vm.copy(row.productNo)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(row.id) + " ")]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "images",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-width": "100px",
                          "max-height": "100px",
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "max-width": "100px",
                            "max-height": "100px"
                          },
                          attrs: { src: row.coverUrl },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(row.coverUrls)
                            }
                          }
                        })
                      ]
                    )
                  ])
                }
              },
              {
                key: "cutImages",
                fn: function(row) {
                  return _c("div", {}, [
                    row.cutCoverUrl
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "max-width": "100px",
                              "max-height": "100px",
                              display: "flex",
                              "flex-direction": "column",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px",
                                "margin-bottom": "6px"
                              },
                              attrs: { src: row.cutCoverUrl || "" },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg([row.cutCoverUrl])
                                }
                              }
                            }),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "取消裁剪封面,用第一张图作为封面",
                                  "ok-text": "Yes",
                                  "cancel-text": "No"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.deleteImage(row)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { size: "small" }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "description",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticStyle: { "max-width": "150px" } },
                      [
                        _c(
                          "a-popover",
                          {
                            attrs: {
                              title: "商品完整标题及补充信息",
                              trigger: "hover"
                            }
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("p", [_vm._v(_vm._s(row.productName))]),
                              _c("div"),
                              _c("p", [_vm._v("补充信息:")]),
                              _c("p", [
                                _vm._v(
                                  "用户自定义瑕疵：" +
                                    _vm._s(
                                      _vm.computedProductRemark(
                                        row.productRemark,
                                        "customRemark"
                                      )
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "选择瑕疵：" +
                                    _vm._s(
                                      _vm.computedProductRemark(
                                        row.productRemark,
                                        "coinStateRemark"
                                      )
                                    )
                                )
                              ])
                            ]),
                            row.isGrayUser == 1
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: { color: "gray" },
                                    attrs: { type: "link" },
                                    on: {
                                      click: function($event) {
                                        return _vm.copy(row.productRemark)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(row.productName) + " ")]
                                )
                              : _vm._e(),
                            row.isGrayUser == 0
                              ? _c(
                                  "a",
                                  {
                                    attrs: { type: "link" },
                                    on: {
                                      click: function($event) {
                                        return _vm.copy(row.productRemark)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.productName.substring(0, 14)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "rgb(217,13,38)",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v("￥" + _vm._s(row.finalPrice))]
                        ),
                        row.reviewStatus === 0
                          ? _c("p", { staticStyle: { color: "#1890ff" } }, [
                              _vm._v("待审核")
                            ])
                          : _vm._e(),
                        row.reviewStatus === 1
                          ? _c("p", { staticStyle: { color: "green" } }, [
                              _vm._v("推荐")
                            ])
                          : _vm._e(),
                        row.reviewStatus === 2
                          ? _c("p", [_vm._v("一般")])
                          : _vm._e(),
                        row.reviewStatus === 3
                          ? _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v(" 处理 "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(row.reviewDetail.reviewReason) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row, function(tag, key) {
                      return _c(
                        "a-tag",
                        { key: key, attrs: { color: "blue" } },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "userName",
                fn: function(row) {
                  return _c("div", {}, [
                    row.isGrayUser == 1
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "#1890ff" },
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.userIdSearch(row.userId)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(row.userName) + " ")]
                        )
                      : _vm._e(),
                    row.isGrayUser == 0
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "#1890ff" },
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.userIdSearch(row.userId)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(row.userName) + " ")]
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      [
                        _c(
                          "a-popover",
                          {
                            attrs: { title: "审核", trigger: "click" },
                            on: { visibleChange: _vm.closeReviewModel },
                            model: {
                              value: row.id == _vm.reviewProductId,
                              callback: function($$v) {
                                _vm.$set(row, "id == reviewProductId", $$v)
                              },
                              expression: "row.id == reviewProductId"
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "content" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "20px" },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.reviewHandle(row.id, 1)
                                          }
                                        }
                                      },
                                      [_vm._v("精品")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "120px" },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.reviewHandle(row.id, 2)
                                          }
                                        }
                                      },
                                      [_vm._v("普品")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "120px" },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.reviewHandle(row.id, 3)
                                          }
                                        }
                                      },
                                      [_vm._v("差品")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: {
                                          width: "350px",
                                          margin: "20px"
                                        },
                                        attrs: {
                                          allowClear: "",
                                          size: "small",
                                          placeholder: "处理"
                                        },
                                        model: {
                                          value: _vm.reviewHandleStatus,
                                          callback: function($$v) {
                                            _vm.reviewHandleStatus = $$v
                                          },
                                          expression: "reviewHandleStatus"
                                        }
                                      },
                                      _vm._l(
                                        _vm.reviewHandleStatusList,
                                        function(item) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: item.value,
                                              attrs: { value: item.value }
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.reviewHandle(row.id, -1)
                                      }
                                    }
                                  },
                                  [_vm._v("拒绝（驳回）")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticStyle: { "margin-left": "80px" },
                                    on: { click: _vm.hideReviewModal }
                                  },
                                  [_vm._v("关闭")]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        ),
                        _c(
                          "a-button",
                          {
                            staticClass: "ml-10 mb-10",
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.clickReview(row.id)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      ],
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row, 1)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.appreciate(row)
                            }
                          }
                        },
                        [_vm._v("鉴赏")]
                      ),
                      row.onShelf === 0
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认上架吗?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.racking(row, 1)
                                }
                              }
                            },
                            [
                              false
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { size: "small" }
                                    },
                                    [_vm._v("上架")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      row.onShelf === 1
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认下架吗?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.racking(row, 0)
                                }
                              }
                            },
                            [
                              false
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { size: "small" }
                                    },
                                    [_vm._v("下架")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认删除么?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.productNo)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { size: "small", type: "danger" }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.downloadCode(row)
                            }
                          }
                        },
                        [_vm._v("下载海报")]
                      )
                    ],
                    2
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "录入商品",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "用户", prop: "userId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        size: "small",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.forms.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "userId", $$v)
                        },
                        expression: "forms.userId"
                      }
                    },
                    _vm._l(_vm.users, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "20px",
                                  "max-height": "20px",
                                  "border-radius": "50%",
                                  "margin-right": "10px"
                                },
                                attrs: { src: item.userAvatar, alt: "" }
                              }),
                              _c("span", { attrs: { text: item.userName } }, [
                                _vm._v(_vm._s(item.userName))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileList
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChange
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "价格", prop: "finalPrice" } },
                [
                  _c("a-input", {
                    attrs: { size: "small", placeholder: "请输入价格" },
                    model: {
                      value: _vm.forms.finalPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "finalPrice", $$v)
                      },
                      expression: "forms.finalPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "运费", prop: "logisticsFee" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入运费0元即为包邮"
                    },
                    model: {
                      value: _vm.forms.logisticsFee,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "logisticsFee", $$v)
                      },
                      expression: "forms.logisticsFee"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签" } },
                [
                  _vm._l(_vm.forms.tags, function(item, key) {
                    return _c(
                      "a-tag",
                      {
                        key: key,
                        attrs: { closable: "" },
                        on: {
                          close: function($event) {
                            return _vm.forms.tags.splice(key, 1)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  _vm.inputVisible
                    ? _c("a-input", {
                        ref: "input",
                        style: { width: "78px" },
                        attrs: {
                          type: "text",
                          size: "small",
                          value: _vm.inputValue
                        },
                        on: {
                          change: _vm.handleInputChange,
                          blur: _vm.handleInputConfirm,
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.inputVisible
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            background: "#fff",
                            borderStyle: "dashed"
                          },
                          on: { click: _vm.showInput }
                        },
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _vm._v(" New Tag ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种板块", prop: "coinSid" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        size: "small",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.searchData },
                      model: {
                        value: _vm.forms.coinSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinSid", $$v)
                        },
                        expression: "forms.coinSid"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.sid } },
                        [_vm._v(_vm._s(item.coinKindName))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "文字内容", prop: "productName" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      "auto-size": "",
                      size: "small",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.forms.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productName", $$v)
                      },
                      expression: "forms.productName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "发布时间", prop: "start_time" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      "show-time": "",
                      size: "small",
                      defaultValue: _vm.moment(new Date(), "YYYY-MM-DD"),
                      placeholder: "选择日期"
                    },
                    on: { change: _vm.changeTime }
                  })
                ],
                1
              ),
              false
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "商品备注", prop: "productRemark" } },
                    [
                      _c("a-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.forms.productRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "productRemark", $$v)
                          },
                          expression: "forms.productRemark"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage }
          })
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 800, title: "出价记录", footer: null },
          on: { cancel: _vm.cancelModal },
          model: {
            value: _vm.visiblePrice,
            callback: function($$v) {
              _vm.visiblePrice = $$v
            },
            expression: "visiblePrice"
          }
        },
        [
          _c(
            "div",
            { staticClass: "take-box" },
            [
              _c("a-table", {
                attrs: {
                  pagination: _vm.paginationModal,
                  loading: _vm.loadingModal,
                  columns: _vm.takeColumns,
                  "data-source": _vm.messages,
                  rowKey: function(record, n) {
                    return n
                  }
                },
                on: { change: _vm.changePageModal }
              })
            ],
            1
          )
        ]
      ),
      _c("AddGoodsAppreciate", {
        ref: "add-appreciate",
        attrs: { detail: _vm.detail },
        on: { success: _vm.getData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }